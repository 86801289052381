import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import TextStack from "../TextStack/TextStack"

const StyledGridItem = styled.div`
    width: 100%;

    ${({ theme }) => theme.mq.desktop } {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 5%;
    }

    ${({ theme }) => theme.mq.widescreen} {
        column-gap: 5%;
    }
`

const StyledTextContainer = styled.div`
    grid-column: ${({ reversed }) => reversed ? "3" : "1"};
    grid-row: 1;
`

const StyledImageContainer = styled.div`
    grid-column: ${({ reversed }) => reversed ? "1 / span 2" : "2 / span 3"};
`

const GridItem = ({className, title, heading, text, image, ...props}) => (
    <StyledGridItem className={className}>
        <StyledTextContainer {...props}>
            <TextStack span={title} title={heading} paragraph={text} {...props}/>
        </StyledTextContainer>
        <StyledImageContainer {...props}>
            <Img fluid={image} {...props} />
        </StyledImageContainer>
    </StyledGridItem>
)

export default GridItem