import styled from "styled-components"
import Img from "gatsby-image"

import GridItem from "../../components/molecules/GridItem/GridItem"

export const Grid = styled.div`
  padding: 100px 0;

  ${({ theme }) => theme.mq.desktop} {
    display: grid;
  }
`
export const StyledGridItem = styled(GridItem)`
  margin: 64px 0;
  padding: 0 32px;

  ${({ theme }) => theme.mq.desktop} {
    padding: 100px 0;
    margin: ${({ reversed }) => (reversed ? "64px auto 0 0" : "64px 0 0 auto")};
    width: 90%;
  }
`

export const GridItemImages = styled.div`
  ${({ theme }) => theme.mq.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

export const StyledHeading = styled.h2`
  text-align: center;
  padding: 0 32px;
  grid-row: ${({ order }) => order};

  ${({ theme }) => theme.mq.desktop} {
    width: 60%;
    margin: 0 auto;
    font-size: 3.2rem;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`

export const StyledGridItemInner = styled.div`
  margin: 64px 0;
  padding: 0 32px;
  width: 100%;

  ${({ theme }) => theme.mq.desktop} {
    display: grid;
    grid-template-columns: 3fr 4fr;
    column-gap: 5%;
    padding: 100px 0 0 0;
    margin: 64px auto 0 auto;
    width: 80%;
  }

  ${({ theme }) => theme.mq.widescreen} {
    column-gap: 15%;
  }
`

export const StyledTextContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
`

export const StyledImageContainer = styled.div`
  grid-column: 2;
`

export const StyledImgSecond = styled(Img)`
  margin: 32px auto;

  ${({ theme }) => theme.mq.desktop} {
    margin-bottom: 128px;
    width: 80%;
  }

  ${({ theme }) => theme.mq.widescreen} {
  }
`

export const Odleglosci = styled.div`
  padding: 0 32px;
  margin-bottom: 1.6rem;

  ${({ theme }) => theme.mq.desktop} {
    width: 80%;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10%;
    margin: 3.2rem auto 6.4rem auto;
  }
`

export const SectionHeading = styled.h2`
  font-size: 2.4rem;
  font-family: ${({ theme }) => theme.font.family.greta};
  margin-top: 0.8rem;

  ${({ theme }) => theme.mq.desktop} {
    font-size: 3.2rem;
  }

  ${({ theme }) => theme.mq.widescreen} {
    font-size: 4rem;
  }

  ${({ theme }) => theme.mq.fullhd} {
    font-size: ${({ theme }) => theme.font.size.xl};
  }
`

export const StyledList = styled.ul`
  margin-top: 1.6rem;
  list-style-type: none;

  ${({ theme }) => theme.mq.desktop} {
    margin: 0;
  }
`

export const StyledListItem = styled.li`
  margin-bottom: 0.8rem;
`
