import React from "react"
import Img from "gatsby-image"
import TextStack from "../../components/molecules/TextStack/TextStack"
import MainTemplate from "../MainTemplate/MainTemplate"

import location2 from "../../images/location-4.jpg"
import location3 from "../../images/location-5.jpg"

import Footer from "../../components/organisms/Footer/Footer"
import Hero from "../../components/organisms/Hero/Hero"

import {
  Grid,
  StyledGridItem,
  GridItemImages,
  StyledHeading,
  StyledImage,
  StyledGridItemInner,
  StyledTextContainer,
  StyledImageContainer,
  StyledImgSecond,
  Odleglosci,
  SectionHeading,
  StyledList,
  StyledListItem,
} from "./LocationTemplate.styles"

const LocationTemplate = ({ heroImage, sections, firstSection, image }) => (
  <MainTemplate>
    <Hero title="Bieszczady" images={heroImage} subpage />
    <Grid>
      <StyledHeading>
        Bieszczady są jednym z najbardziej atrakcyjnych turystycznie regionów
        Polski, dzięki unikalnym walorom obejmującym bogactwo przyrody,
        krajobrazu i zabytków historii.
      </StyledHeading>
      <StyledGridItemInner>
        <StyledTextContainer>
          <TextStack title={firstSection.title} paragraph={firstSection.text} />
        </StyledTextContainer>
        <StyledImageContainer>
          <Img fluid={firstSection.image} />
        </StyledImageContainer>
      </StyledGridItemInner>
      <Odleglosci>
        <SectionHeading>Odległości względem innych miast</SectionHeading>
        <StyledList>
          <StyledListItem>
            Olchowiec - lotnisko Jasionka / 142 km
          </StyledListItem>
          <StyledListItem>Olchowiec – Rzeszów / 129 km</StyledListItem>
          <StyledListItem>Olchowiec – Przemyśl / 100 km</StyledListItem>
          <StyledListItem>Olchowiec – Arłamów / 60 km</StyledListItem>
          <StyledListItem>
            Olchowiec – Radoszyce (Słowacja) / 55 km
          </StyledListItem>
          <StyledListItem>
            Olchowiec – Krościenko (Ukraina) / 39 km
          </StyledListItem>
          <StyledListItem>Olchowiec - Sanok / 38 km</StyledListItem>
          <StyledListItem>Olchowiec - Ustrzyki Dolne / 32 km</StyledListItem>
          <StyledListItem>Olchowiec - Cisna / 28 km</StyledListItem>
        </StyledList>
      </Odleglosci>
      <StyledImgSecond fluid={image} />
      {sections.map((section, index) =>
        index % 2 ? (
          <StyledGridItem
            key={index}
            heading={section.title}
            text={section.text}
            image={section.image}
            reversed
          />
        ) : (
          <StyledGridItem
            key={index}
            heading={section.title}
            text={section.text}
            image={section.image}
          />
        )

        // console.log(`XD: ${section.image}`)
      )}
      <StyledHeading order={5}>
        Takiego projektu nie ma w całych Bieszczadach, na Podkarpaciu, ani nawet
        w Polsce
      </StyledHeading>

      <GridItemImages>
        <StyledImage src={location2} />
        <StyledImage src={location3} />
      </GridItemImages>
    </Grid>
    <Footer />
  </MainTemplate>
)

export default LocationTemplate
