import React from "react"
import { graphql } from "gatsby"
import LocationTemplate from "../templates/LocationTemplate/LocationTemplate"

const Location = ({ data }) => {
  const heroSources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const sections = [
    {
      id: 1,
      title: "Atrakcje turystyczne regionu",
      text:
        "Dziedzictwo przyrodnicze i kulturowe Bieszczad sprawia, że z roku na rok znacząco zwiększa się liczba turystów odwiedzających te urzekające tereny. Zasadniczym atutem turystycznym regionu jest jeden z najbardziej malowniczo położonych parków narodowych naszego kraju – Bieszczadzki Park Narodowy.Istniejąca na terenie parku sieć szlaków turystycznych o długości przeszło 130 km stwarza znakomite warunki do kontaktu z dziką przyrodą. Park stanowi ostoję dla rzadkich gatunków dzikich zwierząt takich jak niedźwiedzie brunatne, wilki, rysie, żbiki, żubry i wiele innych. Poza bezkonkurencyjnymi walorami przyrodniczo-geograficznymi, na szczególną uwagę turystów zasługują także atrakcje historyczno – kulturowe takie jak Bieszczadzka Kolejka Leśna, park miniatur, szlak architektury drewnianej z zabytkowymi obiektami sakralnymi oraz liczne muzea, skanseny i galerie. Zalew Soliński stwarza idealne warunki dla amatorów żeglarstwa i wszelkich innych atrakcji wodnych.",
      image: data.location1.childImageSharp.fluid,
    },
    {
      id: 2,
      title: "Rozwój infrastruktury turystycznej",
      text:
        "Inwestycje w turystykę zapewniają nowe możliwości rozwojowe, szczególnie dla regionów, które chlubią się unikalnymi walorami przyrodniczymi i kulturowymi. Taki potencjał bez wątpienia mają Bieszczady, które są uznawane za jeden z piękniejszych regionów w Polsce. Aby urozmaicić ofertę turystyczną Bieszczad i przyśpieszyć ich rozwój od wielu lat wdrażane są projekty inwestycje, w ramach których powstały nowe drogi, poprawiono infrastrukturę wodno-kanalizacyjną i ulepszono infrastrukturę turystyczną i rekreacyjną. Obecnie w trakcie realizacji są kolejne inwestycje w tym m.in. budowa kolejki gondolowej, budowa mariny, rozbudowa infrastruktury drogowej oraz kolejowej. Wszystkie zamierzone inwestycje z pewnością przyczynią się do poprawy atrakcyjności turystycznej regionu.",
      image: data.location2.childImageSharp.fluid,
    },
    {
      id: 3,
      title: "Kolej gondolowa oraz wieża widokowa nad Soliną",
      text:
        "Solina jest jedną z najbardziej znanych miejscowości turystycznych w rejonie Bieszczad. Co roku zalew soliński odwiedza ok. 1,4 mln turystów. Aby lepiej wyeksponować walory turystyczne tego wyjątkowego miejsca, Polskie Koleje Linowe stworzyły ofertę realizacji projektu kolei gondolowej oraz wieży widokowej, które uatrakcyjnią ofertę turystyczną okolicy.  Kolej gondolowa i wieża widokowa nad Soliną są jednymi z kluczowych projektów inwestycyjnych dla Polskich Kolei Linowych na najbliższe lata. ",
      image: data.location3.childImageSharp.fluid,
    },
    {
      id: 4,
      title: "Ekomarina Polańczyk",
      text:
        "W Polańczyku nad Jeziorem Solińskim powstaje nowoczesna ekomarina z basenami pływającymi na wodzie, wypożyczalnią luksusowych motorówek i szkółką żeglarską. Inwestycja powstaje z inicjatywy Daniela Wojtasa, współwłaściciela Hotelu Skalnego SPA w Polańczyku. W środku mariny znajdują się pływające baseny z podwieszanym dnem – większy o wymiarach 20 na 12 m i brodzik 12 na 6 m. Baseny są ogrodzone i całkowicie bezpieczne dla dzieci. Przy obiekcie funkcjonują wypożyczalnie sprzętu pływającego, w których można wypożyczyć między innymi: jachty, łodzie i katamarany spacerowe oraz kajaki z napędem elektrycznym. Na nabrzeżu powstała piaszczysta plaża miesząca około 60 osób. W trakcie budowy jest restauracja z tarasami pod żaglami, która będzie się specjalizować w przygotowywaniu dań z ryb, zwłaszcza w tych złowionych w Jeziorze Solińskim. Na lądzie powstanie również Szkoła Żeglarska z salką szkoleniową, 20 żaglówkami typu Optymist, bosmanatem, prysznicami, toaletami oraz pomieszczeniem do zrzutu nieczystości z toalet chemicznych, dzięki któremu obiekt będzie w pełni ekologiczny.",
      image: data.location4.childImageSharp.fluid,
    },
    {
      id: 5,
      title: "Rozwój infrastruktury drogowej",
      text:
        "W styczniu 2020 r. po sześciu latach od ogłoszenia przetargu została oddana do użytkowania obwodnica Sanoka w ciągu drogi krajowej nr 28 Zator – Medyka. Obwodnica znacząco ułatwiła dojazd w Bieszczady, a Sanok został odciążony z ruchu tranzytowego, co poprawiło przepustowość i bezpieczeństwo drogowe w mieście. Koszty inwestycji wyniosły ok. 170 mln zł. Na 7-kilometrowym odcinku obwodnicy Sanoka zbudowano 12 obiektów inżynierskich, 4 obiekty na potokach górskich, 6 przejść dla zwierząt i przepustów pod obwodnicą oraz 8 przepustów ramowych pod drogami bocznymi.",
      image: data.location5.childImageSharp.fluid,
    },
    {
      id: 6,
      title: "Rozwój infrastruktury kolejowej",
      text:
        "Utrzymanie oraz zwielokrotnienie liczby połączeń kolejowych w Bieszczadach i stworzenie podróżującym transportem kolejowym możliwości przekraczania granic słowackiej i ukraińskiej to działania, które mają przyczynić się do zrównoważonego rozwoju powiatów sanockiego, leskiego i bieszczadzkiego. Dlatego na przełomie 2019 i 2020 roku PKP Polskie Linie Kolejowe zleciły wykonanie dokumentacji projektowych dla rewitalizacji linii kolejowej 108 Jasło – Zagórz, linii 107 Zagórz – Łupków oraz budowy łącznicy kolejowej Jedlicze – Szebnie. Pracę za ponad 8 mln zł ma wykonać konsorcjum firm z liderem IDOM Inżynieria, Architektura i Doradztwo. Kluczowym elementem inwestycji będzie przebudowa stacji i przystanków. Realizacja projektu gwarantuje pasażerom większy komfort obsługi oraz łatwiejszy dostęp do pociągów.",
      image: data.location6.childImageSharp.fluid,
    },
  ]

  const firstSection = {
    title: "Lokalizacja obiektu",
    text:
      "Teren inwestycji to Polska południowo – wschodnia, woj. Podkarpackie. Działka inwestycyjna obejmuje teren o powierzchni 7 hektarów, malowniczo położona na wzniesieniu półwyspu w miejscowości Olchowiec, w sercu Bieszczadów, z trzech stron otoczona wodami Zalewu Solińskiego (południowe odnogi zalewu, przy największym dopływie, rzece San), od lądu sąsiadująca z najdzikszym górskim pasmem Bieszczadów – Otrytem. Przepiękny widok na jezioro, góry i lasy, bezpośredni dostęp do jeziora i lasów, dogodny dojazd, sąsiedztwo wielu szlaków pieszych i rowerowych, doliny rzeki San, spokój, cisza i piękna przyroda czynią to miejsce naprawdę wyjątkowym. Takie położenie sprawia, że jest do doskonała baza wypadowa do wszystkich zakątków malowniczych Bieszczad.",
    image: data.firstImage.childImageSharp.fluid,
  }

  return (
    <LocationTemplate
      heroImage={heroSources}
      sections={sections}
      firstSection={firstSection}
      image={data.location20.childImageSharp.fluid}
    />
  )
}

export const query = graphql`
  {
    mobileImage: file(name: { eq: "location-hero-mobile" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(name: { eq: "location-hero-desktop" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    allImages: allFile(
      filter: { relativeDirectory: { eq: "location" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    firstImage: file(name: { eq: "0-umiejscowienie-inwestycji" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    location20: file(name: { eq: "lokalizacja-2" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    location1: file(name: { eq: "1-location-1000x" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    location2: file(name: { eq: "2-location-1000x" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    location3: file(name: { eq: "3-kolej" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    location4: file(name: { eq: "4-ekomarina-1000x" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    location5: file(name: { eq: "6-drogi" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    location6: file(name: { eq: "7-kolejka" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Location
